import { IHerramientaGenerica } from '@app/models/herramienta';
import { IItemCertificacion, IRegistrarCertificacion } from '@app/models/herramienta/ICertificacion';
import { IFotoHerramienta, IFotoHerramientaEliminada, IHerramienta, ISummaryHerramientas } from '@app/models/herramienta/IHerramientaGeneral';
import { IItemMantenimiento, IRegistrarMantenimiento } from '@app/models/herramienta/IMantenimiento';
import axios from '@app/utils/request/axios';

export const agregarHerramientaService = async (formData: FormData) => {
  try {
    const { data } = await axios.post('Herramientas', formData);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getHerramientaById = async (id: string | number) => {
  try {
    const { data } = await axios.get<IHerramienta>(`Herramientas/${id}`);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getHerramientaByNcService = async (nc: string) => {
  try {
    const { data } = await axios.get<IHerramienta>(`Herramientas/GetByNc/${nc}`, {
      params: {
        nc,
      },
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getFotosHerramientaById = async (id: string | number) => {
  try {
    const { data } = await axios.get<IFotoHerramienta[]>(`Herramientas/${id}/Fotos`);

    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getFotosHerramientaEliminadaById = async (id: string | number) => {
  try {
    const { data } = await axios.get<IFotoHerramientaEliminada[]>(`Herramientas/${id}/Fotos/Eliminado`);
    return data;
  } catch (ex) {
    throw ex;
  }
}

export const updateFotoHerramientaEliminada = async (herramientaId: string | number, foto: IFotoHerramienta) => {
  try {
    const formData = new FormData();

    if (foto.id) {
      formData.append('id', foto.id);
      await axios.put(`Herramientas/${herramientaId}/Fotos/Eliminado`, formData)
    }
  } catch (ex) {
    throw ex;
  }
}

export const upsertFotoHerramienta = async (herramientaId: string | number, foto: IFotoHerramienta) => {
  try {
    const formData = new FormData();

    if (foto.id) {
      formData.append('id', foto.id);
    }

    formData.append('foto', foto.data);
    await axios.put(`Herramientas/${herramientaId}/Fotos`, formData);
  } catch (ex) {
    throw ex;
  }
};

export const getHerramientas = async (page = 1, pageSize = 25, filtro: string = '', tipo: number = 0) => {
  try {
    const { data } = await axios.get<ISummaryHerramientas>('Herramientas', {
      params: { page, pageSize, filter: filtro, tipo },
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getHerramientasEliminadas = async (page = 1, pageSize = 25, filtro: string = '', tipo: number = 0) => {
  try {
    const { data } = await axios.get<ISummaryHerramientas>('Herramientas/eliminadas', {
      params: { page, pageSize, filter: filtro, tipo },
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};


export const getHerramienta = async (idHerramienta: number) => {
  try {
    const { data } = await axios.get<IHerramienta>(`Herramientas/${idHerramienta}`);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const deleteHerramienta = async (id: number) => {
  try {
    await axios.delete(`Herramientas/${id}`);
  } catch (ex) {
    throw ex;
  }
};

export const updateRestaurarHerramientaEliminada = async (id: number) => {
  try {
    const { data } = await axios.put(`Herramientas/${id}/restaurar`);
    return data;
  } catch (ex) {
    throw ex;
  }
}

export const getHerramientaGenericaById = async (id: string | number) => {
  try {
    const { data } = await axios.get<IHerramientaGenerica>(`HerramientaGenerica/${id}`);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const agregarMantenimiento = async (request: IRegistrarMantenimiento) => {
  try {
    const { data } = await axios.post('Mantenimiento', request);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const agregarCertificacion = async (request: IRegistrarCertificacion) => {
  try {
    const { data } = await axios.post('Certificacion', request);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const updateHerramienta = async (request: IHerramienta) => {
  try {
    const { data } = await axios.put<IHerramienta>('Herramientas', request);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getCertificaciones = async (herramientaId: number) => {
  try {
    const { data } = await axios.get<IItemCertificacion[]>(`Herramientas/${herramientaId}/certificaciones`);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getMantenimientos = async (herramientaId: number) => {
  try {
    const { data } = await axios.get<IItemMantenimiento[]>(`Herramientas/${herramientaId}/mantenimientos`);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getHerramientaEliminadaById = async (herramientaId: number) => {
    try {
        const response = await axios.get<IHerramienta>(`Herramientas/eliminadas/${herramientaId}`, {
            params: {
                herramientaId,
            },
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
};
