import Loading from '@app/components/Loading';
import useError from '@app/hooks/useError';
import { IStatusTRabajador, ITrabajadorEntradaSalidaDetalle } from '@app/models/entradas/ITrabajadorEntradaSalida';
import { estatusTrabajadorService, trabajadorTarjetaService } from '@app/services/trabajador.service';
import { Search, Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Prestamos() {
  const [cargando, setCargando] = useState<boolean>(false);
  const [estadoTrabajador, setEstadoTrabajador] = useState<IStatusTRabajador>({
    caducidad: '',
    codigoemp: 0,
    name: '',
    objectId: 0,
    status: '',
  });
  const [detalleTrabajador, setDetalleTrabajador] = useState<ITrabajadorEntradaSalidaDetalle>({
    codigocol: null,
    correo: '',
    departamento: '',
    empresa: '',
    gerencia: '',
    nivelFirma: '',
    nombre: '',
    pueId: 0,
    puesto: '',
    tipo: '',
    unoId: 0,
    usuarioId: '',
    tarjeta: 0,
  });
  const [numeroTarjeta, setNumeroTarjeta] = useState<{ codigo: string; mostrarCodigo: boolean }>({
    codigo: '',
    mostrarCodigo: false,
  });
  const refLectora = useRef(null);
  const navigate = useNavigate();
  const { setError, ErrorAlert } = useError();

  const leerTarjeta = (codigo: string) => {
    setNumeroTarjeta(valores => ({ ...valores, codigo }));
  };

  const obtenerTrabajador = async () => {
    try {
      setCargando(true);
      const status = await estatusTrabajadorService(!!+numeroTarjeta.codigo ? +numeroTarjeta.codigo : 0);
      setEstadoTrabajador(status.data);
      if (status.data.caducidad === 'EXPIRADA') {
        return;
      }
      const { data } = await trabajadorTarjetaService(+status.data.codigoemp);
      const detalle =
        data?.codigocol === undefined || data?.codigocol === null
          ? { ...data, nombre: status?.data?.name, puesto: 'Sindicato' }
          : data;
      setDetalleTrabajador({ ...detalle, tarjeta: status.data.objectId });
      localStorage.setItem('cardNumber', numeroTarjeta.codigo || '0');
    } catch (ex) {
      setError(ex?.response?.data);
      setDetalleTrabajador({
        codigocol: null,
        correo: '',
        departamento: '',
        empresa: '',
        gerencia: '',
        nivelFirma: '',
        nombre: '',
        pueId: 0,
        puesto: '',
        tipo: '',
        unoId: 0,
        usuarioId: '',
        tarjeta: 0,
      });
    } finally {
      setCargando(false);
    }
  };

  const mostrarContrasena = () => {
    setNumeroTarjeta(valores => ({ ...valores, mostrarCodigo: !valores.mostrarCodigo }));
  };

  useEffect(() => {
    return () => {
      setCargando(false);
      setNumeroTarjeta({
        codigo: '',
        mostrarCodigo: false,
      });
      setEstadoTrabajador({
        caducidad: '',
        codigoemp: 0,
        name: '',
        objectId: 0,
        status: '',
      });
      setDetalleTrabajador({
        codigocol: null,
        correo: '',
        departamento: '',
        empresa: '',
        gerencia: '',
        nivelFirma: '',
        nombre: '',
        pueId: 0,
        puesto: '',
        tipo: '',
        unoId: 0,
        usuarioId: '',
        tarjeta: 0,
      });
    };
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      {!!cargando && <Loading mensaje="Buscando trabajador" />}
      <ErrorAlert />
      <h1 className="text-center mb-5">Entrada de Almacén</h1>
      <Row>
        <Col xs={4} sm={4} md={4} lg={4}>
          <InputGroup>
            <Form.Control
              type={numeroTarjeta.mostrarCodigo ? 'number' : 'password'}
              name="numeroTrabajador"
              value={numeroTarjeta.codigo}
              onInput={(event: any) => {
                leerTarjeta(event.target.value);
              }}
              onKeyUp={event => {
                if (event.key === 'Enter' && !!numeroTarjeta.codigo.length) {
                  obtenerTrabajador();
                }
              }}
              onBlur={() => {
                refLectora.current.focus();
              }}
              ref={refLectora}
              autoFocus={true}
              placeholder="Número Tarjeta"
            />
            <Button variant="primary" onClick={() => mostrarContrasena()}>
              {numeroTarjeta.mostrarCodigo ? <Visibility /> : <VisibilityOff />}
            </Button>
            <Button variant="primary" onClick={() => obtenerTrabajador()} disabled={!!!numeroTarjeta.codigo.length}>
              <Search />
            </Button>
          </InputGroup>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8}>
          <div className="w-100 d-flex align-items-center justify-content-end">
            <Button
              onClick={() => navigate(`/registro-entrada/${estadoTrabajador.codigoemp}`)}
              variant="secondary"
              disabled={estadoTrabajador.status.toLowerCase() === 'inactivo' || !!!numeroTarjeta.codigo}
            >
              Continuar
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="d-flex align-items-center flex-column" xs={12} sm={12} md={12} lg={12}>
          <div className="w-100">
            <p className="fw-bold">Información Trabajador</p>
            <Card
              className="mb-4"
              style={
                estadoTrabajador.status.toLowerCase() === 'activo'
                  ? { border: 'solid 3px green', borderRadius: '5px' }
                  : estadoTrabajador.status.toLowerCase() === 'inactivo'
                  ? { border: 'solid 3px red', borderRadius: '5px' }
                  : { border: '1px solid #00000020', borderRadius: '5px' }
              }
            >
              <Card.Header className="p-2">
                <p className="m-2">
                  <span className="fw-bold">Nombre: </span>
                  {detalleTrabajador.nombre}
                </p>
                <p className="m-2">
                  <span className="fw-bold">Empresa: </span>
                  {detalleTrabajador.empresa}
                </p>
                <p className="m-2">
                  <span className="fw-bold">Departamento: </span>
                  {detalleTrabajador.departamento}
                </p>
                <p className="m-2">
                  <span className="fw-bold">Puesto: </span>
                  {detalleTrabajador.puesto}
                </p>
                <p className="m-2">
                  <span className="fw-bold">Status: </span>
                  {estadoTrabajador.status}
                </p>
                <p className="m-2">
                  <span className="fw-bold">Tarjeta: </span>
                  {estadoTrabajador.caducidad}
                </p>
              </Card.Header>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
