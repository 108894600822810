import Loading from '@app/components/Loading';
import { AppRoutes } from '@app/contants/app-routes';
import useError from '@app/hooks/useError';
import { IDisponibilidadHerramienta } from '@app/models/herramienta/IDisponibilidad';
import { IHerramienta, ISummaryHerramientas } from '@app/models/herramienta/IHerramientaGeneral';
import { getHerramientasEliminadas } from '@app/services';
import { getReporteHerramientasEliminadasService } from '@app/services/reportes.service';
import { descargarArchivo } from '@app/utils/descargarArchivo';
import ArrowBack from '@mui/icons-material/ArrowBack';
import RestoreIcon from '@mui/icons-material/Restore';
import SearchIcon from '@mui/icons-material/Search';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Form, Image, InputGroup, Row, Spinner, Table } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
// Importar los nuevos tipos de estado y modal
import ImagenEliminadoModal from '@app/components/modals/ImagenEliminadoModal';
import { ROLES } from '@app/models/enums';
import { updateRestaurarHerramientaEliminada } from '@app/services/herramienta.service';
import { useReporteHerramientaEliminada } from '../../utils/pdfMake/reporteHerramientaEliminada';
//import { useReporteHerramientaEliminada } from '@app/utils/pdfMake/reporteHerramientaEliminada';

export default function TablaHerramienta() {
  const roleUsuario = JSON.parse(localStorage.getItem('credenciales'))?.roles[0]?.toLowerCase();
  const [cargando, setCargando] = useState<boolean>(true);
  const [actualizando, setActualizando] = useState<number | null>(null);
  const [modalImagenShow, setModalImagenShow] = useState<boolean>(false);
  const [imagenSeleccionada, setImagenSeleccionada] = useState<string>('');
  const [mensaje, setMensaje] = useState({ tipo: '', texto: '' });


  const handleImageClick = (imagenBase64, herramienta) => {
    setImagenSeleccionada(imagenBase64);
    setModalImagenShow(true);
    setHerramientasSeleccionadas([herramienta]);
  };

  const handleRestaurarHerramienta = async (id: number) => {
    try {
      setActualizando(id);
      await updateRestaurarHerramientaEliminada(id);
      setHerramientasGenerales(prevState => ({
        ...prevState,
        elementos: prevState.elementos.filter(her => her.id !== id),
      }));
      setMensaje({ tipo: 'success', texto: 'Herramienta restaurada exitosamente' });
      obtenerHerramientas(1, 25, queryBusqueda);
    } catch (ex) {
      setMensaje({ tipo: 'danger', texto: 'Error al restaurar la herramienta: ' + ex.message });
    } finally {
      setActualizando(null);
    }
  };

  const [disponibilidadHerramienta, setDisponibilidadHerramienta] = useState<IDisponibilidadHerramienta>({
    disponibles: 0,
    prestadas: 0,
  });
  const [herramientasSeleccionadas, setHerramientasSeleccionadas] = useState<IHerramienta[]>([]);
  const [paginacion, setPaginacion] = useState<{ numeroPagina: number; paginas: number; masRegistros: boolean }>({
    numeroPagina: 1,
    paginas: 25,
    masRegistros: false,
  });
  const [herramientasGenerales, setHerramientasGenerales] = useState<ISummaryHerramientas>({
    disponible: 0,
    prestada: 0,
    elementos: [],
  });
  const [descargandoReporte, setDescargandoReporte] = useState<boolean>(false);
  const [queryBusqueda, setQueryBusqueda] = useState<string>('');
  const { setError } = useError();
  const { downloadReport } = useReporteHerramientaEliminada()

  const obtenerHerramientas = (
    numPagina: number = paginacion.numeroPagina,
    cantidadElementos: number = 25,
    queryBusqueda: string = ''
  ) => {
    getHerramientasEliminadas(numPagina, cantidadElementos, queryBusqueda)
      .then(res => {
        setHerramientasGenerales(data => ({
          ...data,
          elementos: numPagina === 1 ? res.elementos : [...data.elementos, ...res.elementos],
        }));
        setDisponibilidadHerramienta({
          prestadas: res.prestada,
          disponibles: res.disponible,
        });
        setPaginacion(paginacion => ({
          ...paginacion,
          numeroPagina: numPagina === 1 ? 2 : paginacion.numeroPagina + 1,
          masRegistros: !!res.elementos.length,
        }));
        setTimeout(() => {
          setCargando(false);
        }, 1000);
      })
      .catch(ex => {
        setCargando(false);
        setError(ex);
      });
  };

  const descargarReporte = async () => {
    try {
      setDescargandoReporte(true);
      const response = await getReporteHerramientasEliminadasService(queryBusqueda);
      descargarArchivo(response, 'ReporteHerramientasEliminadas.xlsx');
    } catch (ex) {
      setError(ex);
    } finally {
      setDescargandoReporte(false);
    }
  };

  const generatePDF = async(herramientaId: number) => {
    try {
        setCargando(true);
        await downloadReport(herramientaId);
    } catch (error) {
        setError(error);
    } finally {
        setCargando(false);
    }
};

  const buscarCambioFiltro = () => {
    if (!queryBusqueda || queryBusqueda.length > 2) {
      setCargando(true);
      obtenerHerramientas(1, 25, queryBusqueda);
    }
  };

  useEffect(() => {
    if (!queryBusqueda) {
      buscarCambioFiltro();
    }
  }, [queryBusqueda]);

  useEffect(() => {
    return () => {
      setCargando(true);
      setPaginacion({
        numeroPagina: 1,
        paginas: 25,
        masRegistros: true,
      });
      setHerramientasGenerales({
        disponible: 0,
        prestada: 0,
        elementos: [],
      });
      setDescargandoReporte(false);
      setError('');
    };
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      <Link to={AppRoutes.Herramienta}>
        <ArrowBack className="text-body" />
      </Link>
      {mensaje.texto && (
        <Alert variant={mensaje.tipo} onClose={() => setMensaje({ tipo: '', texto: '' })} dismissible>
          {mensaje.texto}
        </Alert>
      )}
      <h1 className="text-center mb-5">Historial Herramienta: Dada de Baja </h1>

      <Row className="d-flex align-items-end justify-content-between">
        <Col xs={6} sm={3} md={3} lg={3}>
          <Form.Group className="d-flex">
            <InputGroup>
              <Form.Control
                name="herramienta"
                type="search"
                onChange={({ target }) => setQueryBusqueda(target.value)}
                onKeyDown={event => {
                  if (event.keyCode === 13) {
                    buscarCambioFiltro();
                  }
                }}
                placeholder="Buscar por NC o Herramienta"
              />
              <Button variant="primary" onClick={() => buscarCambioFiltro()}>
                <SearchIcon />
              </Button>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col className="d-flex align-items-end justify-content-around" xs={4} sm={4} md={4} lg={4}>
          {ROLES.ADMINISTRADOR === roleUsuario && (
            <Button variant="secondary" onClick={descargarReporte} disabled={descargandoReporte}>
              {descargandoReporte ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  &nbsp;Descargando Reporte
                </>
              ) : (
                'Descargar Reporte'
              )}
            </Button>
          )}
        </Col>
        <Col className="d-flex align-items-end justify-content-around" xs={8} sm={3} md={3} lg={3}>
          <div className="d-flex align-items-center justify-content-center flex-column">
            <p className="fw-bold my-0">Total Herramientas Dadas de Baja</p>
            <p className="my-1">{disponibilidadHerramienta.disponibles}</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="overflow-scroll" md={12} sm={12} xs={12}>
          {cargando ? (
            <Loading mensaje="Cargando herramientas" />
          ) : (
            <InfiniteScroll
              className="mt-5"
              dataLength={herramientasGenerales.elementos.length}
              next={() => obtenerHerramientas(paginacion.numeroPagina, paginacion.paginas, queryBusqueda)}
              height={550}
              loader={
                herramientasGenerales.elementos.length >= paginacion.paginas &&
                paginacion.masRegistros && (
                  <div className="d-flex align-items-center justify-content-center py-4">
                    <Spinner variant="secondary" animation="border" />
                    <p className="mx-2 my-0">Cargando Herramientas</p>
                  </div>
                )
              }
              hasMore={paginacion.masRegistros}
            >
              <Table striped hover bordered>
                <thead>
                  <tr>
                    <th>NC</th>
                    <th>Modelo</th>
                    <th>Serie</th>
                    <th>Herramienta</th>
                    <th>Complemento Nombre</th>
                    <th>Mantenimientos Preventivos</th>
                    <th>Mantenimientos Correctivos</th>
                    <th>Autorizador</th>
                    <th>Observaciones</th>
                    <th>Fecha Dada de Baja</th>
                    <th>Imagen</th>
                    {ROLES.ADMINISTRADOR === roleUsuario && (
                    <th>Acciones</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {!herramientasGenerales.elementos.length ? (
                    <tr>
                      <td colSpan={13}>No hay herramientas registradas.</td>
                    </tr>
                  ) : (
                    herramientasGenerales.elementos.map((her: IHerramienta) => (
                      <tr key={her.id}>
                        <td>{her.numeroControl}</td>
                        <td>{her.modelo}</td>
                        <td>{her.numeroSerie}</td>
                        <td>{her.herramientaGenerica}</td>
                        <td>{her.complementoNombre}</td>
                        <td>{her.mantenimientos.length}</td>
                        <td>{her.totalCorrectivos}</td>
                        <td>{her.usuarioEliminado}</td>
                        <td>{her.observacionesEliminado}</td>
                        <td>{format(new Date(her.fechaEliminado), 'dd/MM/yyyy HH:mm:ss a')}</td>
                        <td>
                          {her.imagenEliminado && (
                            <Image
                              src={`data:image/jpeg;base64,${her.imagenEliminado}`}
                              alt="Imagen Eliminado"
                              thumbnail
                              style={{ maxWidth: '110px', maxHeight: '110px', cursor: 'pointer' }}
                              onClick={() => handleImageClick(her.imagenEliminado, her)}
                            />
                          )}
                        </td>
                      {ROLES.ADMINISTRADOR === roleUsuario && (
                        <td className="text-right">
                            <>
                              <Button
                                onClick={() => handleRestaurarHerramienta(her.id)}
                                type="button"
                                variant="link"
                                className="text-black p-0"
                                disabled={actualizando === her.id}
                              >
                                {actualizando === her.id ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <RestoreIcon className="mx-2" />
                                )}
                              </Button>
                              <Button
                                        onClick={() => generatePDF(her.id)}
                                        type="button"
                                        variant="link"
                                        className="text-black p-0"
                                        disabled={actualizando === her.id}
                                    >
                                        
                                            <SimCardDownloadIcon className="mx-2" />
                                        
                                    </Button>
                            </>
                        </td>
                            )}
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </InfiniteScroll>
          )}
        </Col>
      </Row>
      <ImagenEliminadoModal
    show={modalImagenShow}
    onHide={() => setModalImagenShow(false)}
    imagenBase64={imagenSeleccionada}
    title={`NC: ${herramientasSeleccionadas[0]?.numeroControl || ''} - Herramienta: ${herramientasSeleccionadas[0]?.herramientaGenerica || ''}`}
/>
    </Container>
  );
}
