import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { format } from 'date-fns';
import logo from '../../assets/images/logo.png';
import { getHerramientaEliminadaById } from '../../services';
import { DINProFontBase64 } from './DINProFontBase64';
import { DINProFontBoldBase64 } from './DINProFontBold_Base64';

export function useReporteHerramientaEliminada  () {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfFonts.pdfMake.vfs['DINProFont_Base64'] = DINProFontBase64;
    pdfFonts.pdfMake.vfs['DINProFontBold_Base64'] = DINProFontBoldBase64;
    pdfMake.fonts = {
        Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-MediumItalic.ttf',
        },
        DINPro: {
            normal: 'DINProFont_Base64',
            bold: 'DINProFontBold_Base64',
            italics: 'DINProFont_Base64',
            bolditalics: 'DINProFontBold_Base64',
        },
    };

    const header = function () {
        return {
            table: {
                widths: [100, '*', '*'],
                body: [
                    [
                        {
                            image: logo,
                            width: 80,
                            border: [false],
                        },
                        {
                            text: 'Herramienta dada de baja',
                            border: [false],
                            margin: [0, 12, 0, 0],
                            style: 'textHeader',
                            alignment: 'center',
                            font: 'DINPro',
                            color: 'white',
                        },
                        {
                            text: `${fechaConLetras}`,
                            border: [false],
                            margin: [0, 15, 0, 0],
                            style: 'textHeaderFolio',
                            alignment: 'right',
                            font: 'DINPro',
                            color: 'white',
                        },
                    ],
                ],
            },
            fillColor: '#cd4003',
            margin: [0, 0, 0, 10],
        };
    };

    const footer = function (currentPage, pageCount) {
        return {
            columns: [
                {
                    text: `Pagina ${currentPage} de ${pageCount}`,
                    alignment: 'center',
                    fontSize: 8,
                    margin: [0, 0, 0, 10],
                },
            ],
            fillColor: '#929395',
            margin: [0, 0, 0, 10],
            height: 50,
        };
    };

    const fechaActual = new Date();
    const opciones: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    const fechaConLetras = fechaActual.toLocaleDateString('es-ES', opciones);
    
    const bodyContent = async (herramientaId: number)=> {
            const {
                numeroControl,
                herramientaGenerica,
                complementoNombre,
                modelo,
                numeroSerie,
                marca,
                mantenimientos,
                totalCorrectivos,
                tipoHerramienta,
                fechaEliminado,
                usuarioEliminado,
                observacionesEliminado,
                imagenEliminado
            }= await getHerramientaEliminadaById(herramientaId);
         
        
            const documentDefinition = {
                header: header,
                footer: footer,
                content: [
                    {
                        text: 'Datos de la Herramienta Dada de Baja',
                        style: 'subheader',
                        alignment: 'center',
                        margin: [0, 10, 0,10],
                    },
                    {
                        columns: [
                            { text: 'Numero de Control:', bold: true },
                            {
                                text: numeroControl || 'N/A',
                                margin: [0, 5, 0, 5],
                            },
        
                        ],
        
                    },
                    {
                        columns: [
                            { text: 'Nombre de la Herramienta:', bold: true },
                            {
                                text: herramientaGenerica || 'N/A',
                                margin: [0, 5, 0, 5],
                            },
                        ],
                    },
                    {
                        columns: [
                            { text: 'Complemento:', bold: true },
                            {
                                text: complementoNombre || 'N/A',
                                margin: [0, 5, 0, 5],
                            },
                        ],
                    },
                    {
                        columns: [
                            { text: 'Modelo:', bold: true },
                            {
                                text: modelo || 'N/A',
                                margin: [0, 5, 0, 5],
                            },
                        ],
                    },
                    {
                        columns: [
                            { text: 'Numero de Serie:', bold: true },
                            {
                                text: numeroSerie || 'N/A',
                                margin: [0, 5, 0, 5],
                            },
                        ],
                    },
                    {
                        columns: [
                            { text: 'Marca:', bold: true },
                            {
                                text: marca || 'N/A',
                                margin: [0, 5, 0, 5],
                            },
                        ],
                    },
                    {
                        columns: [
                            { text: 'Tipo de Herramienta:', bold: true },
                            {
                                text: tipoHerramienta || 'N/A',
                                margin: [0, 5, 0, 5],
                            },
                        ],
                    },
                    {
                        columns: [
                            { text: 'Mantenimientos Preventivos Realizados:', bold: true },
                            {
                                text: mantenimientos.length || 'Ninguno',
                                margin: [0, 5, 0, 5],
                            },
                        ],
                    },
                    {
                        columns: [
                            { text: 'Mantenimientos Correctivos Realizados:', bold: true },
                            {
                                text: totalCorrectivos || 'Ninguno',
                                margin: [0, 5, 0, 5],
                            },
                        ],
                    },
                    {
                        columns: [
                            { text: 'Fecha de Baja:', bold: true },
                            {
                                text: format(new Date(fechaEliminado), 'dd/MM/yyyy HH:mm:ss a') || 'N/A',
                                margin: [0, 5, 0, 5],
                            },
                        ],
                    },
                    {
                        columns: [
                            { text: 'Autorizador:', bold: true },
                            {
                                text: usuarioEliminado || 'N/A',
                                margin: [0, 5, 0, 5],
                            },
                        ],
                    },
                    {
                        columns: [
                            { text: 'Observaciones:', bold: true },
                        ],
                    },
                    {
                        columns: [
                         
                            {
                                text: observacionesEliminado || 'Ninguna',
                                margin: [0, 5, 0, 5],
                            },
                        ],
                    },
                    {
                        text: 'Imagen de la Herramienta Eliminada:',
                        style: 'subheader',
                        alignment: 'center',
                        margin: [0, 10, 0, 10],
                    },
                    imagenEliminado ? {
                        image: 'data:image/jpeg;base64,' + imagenEliminado,
                        width: 200,
                        height: 200,
                        alignment: 'center',
                    } : { text: 'No hay imagen disponible' },
                ],
                styles: {
                    subheader: {
                        fontSize: 14,
                        bold: true,
                    },
                    textHeader: {
                        fontSize: 18,
                        bold: true,
                    },
                    textHeaderFolio: {
                        fontSize: 12,
                    },
                },
            };
        
            pdfMake.createPdf(documentDefinition as any).download();
        
    }
    return { downloadReport: bodyContent };
};
