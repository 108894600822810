import Loading from '@app/components/Loading';
import useError from '@app/hooks/useError';
import { IStatusTRabajador, ITrabajadorEntradaSalidaDetalle } from '@app/models/entradas/ITrabajadorEntradaSalida';
import { usePrestamosPendientesTrabajadorQuery } from '@app/redux/reducers/movimientos.reducer';
import { estatusTrabajadorService, trabajadorTarjetaService } from '@app/services/trabajador.service';
import { tieneEntregaRetrasada } from '@app/utils/herramienta.utils';
import { Search, Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Salida() {
  const [cargando, setCargando] = useState<boolean>(false);
  const [estadoTrabajador, setEstadoTrabajador] = useState<IStatusTRabajador>({
    caducidad: '',
    codigoemp: 0,
    name: '',
    objectId: 0,
    status: '',
  });
  const [detalleTrabajador, setDetalleTrabajador] = useState<ITrabajadorEntradaSalidaDetalle>({
    codigocol: null,
    correo: '',
    departamento: '',
    empresa: '',
    gerencia: '',
    nivelFirma: '',
    nombre: '',
    pueId: 0,
    puesto: '',
    tipo: '',
    unoId: 0,
    usuarioId: '',
    tarjeta: 0,
  });
  const [numeroTarjeta, setNumeroTarjeta] = useState<{ codigo: string; mostrarCodigo: boolean }>({
    codigo: '',
    mostrarCodigo: false,
  });
  const [llamando, setLlamando] = useState<boolean>(false);
  const { data: prestamosPendientes, isFetching: cargandoPrestamos } = usePrestamosPendientesTrabajadorQuery(
    {
      codigoEmp: detalleTrabajador.codigocol ? estadoTrabajador.codigoemp : detalleTrabajador.tarjeta,
    },
    {
      skip: !llamando,
    }
  );

  const refLectora = useRef(null);
  const navigate = useNavigate();
  const { setError, ErrorAlert } = useError();

  const leerTarjeta = (codigo: string) => {
    setNumeroTarjeta(valores => ({ ...valores, codigo }));
  };

  const obtenerTrabajador = async () => {
    try {
      setCargando(true);
      const status = await estatusTrabajadorService(!!+numeroTarjeta.codigo ? +numeroTarjeta.codigo : 0);
      setEstadoTrabajador(status.data);
      if (status.data.caducidad === 'EXPIRADA') {
        return;
      }
      const { data } = await trabajadorTarjetaService(+status.data.codigoemp);
      const detalle =
        data?.codigocol === undefined || data?.codigocol === null
          ? { ...data, nombre: status?.data?.name, puesto: 'Sindicato' }
          : data;
      setDetalleTrabajador({ ...detalle, tarjeta: status.data.objectId });
      setLlamando(true);
      localStorage.setItem('cardNumber', numeroTarjeta.codigo || '0');
    } catch (ex) {
      setError(ex?.response?.data);
      setDetalleTrabajador({
        codigocol: null,
        correo: '',
        departamento: '',
        empresa: '',
        gerencia: '',
        nivelFirma: '',
        nombre: '',
        pueId: 0,
        puesto: '',
        tipo: '',
        unoId: 0,
        usuarioId: '',
        tarjeta: 0,
      });
    } finally {
      setCargando(false);
    }
  };

  const mostrarContrasena = () => {
    setNumeroTarjeta(valores => ({ ...valores, mostrarCodigo: !valores.mostrarCodigo }));
  };

  const mostrarAdvertenciaPrestamos = () => <Alert variant="warning">Tiene 10 o más préstamos pendientes.</Alert>;

  useEffect(() => {
    return () => {
      setCargando(false);
      setNumeroTarjeta({
        codigo: '',
        mostrarCodigo: false,
      });
      setEstadoTrabajador({
        caducidad: '',
        codigoemp: 0,
        name: '',
        objectId: 0,
        status: '',
      });
      setDetalleTrabajador({
        codigocol: null,
        correo: '',
        departamento: '',
        empresa: '',
        gerencia: '',
        nivelFirma: '',
        nombre: '',
        pueId: 0,
        puesto: '',
        tipo: '',
        unoId: 0,
        usuarioId: '',
        tarjeta: 0,
      });
    };
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      {!!cargando && <Loading mensaje="Buscando trabajador" />}
      <ErrorAlert />
      {prestamosPendientes?.length > 9 && mostrarAdvertenciaPrestamos()}
      <h1 className="text-center mb-5">Salida de Almacén</h1>
      <Row>
        <Col xs={4} sm={4} md={4} lg={4}>
          <InputGroup>
            <Form.Control
              type={numeroTarjeta.mostrarCodigo ? 'number' : 'password'}
              name="numeroTrabajador"
              value={numeroTarjeta.codigo}
              onInput={(event: any) => leerTarjeta(event.target.value)}
              onKeyUp={event => {
                if (event.key === 'Enter' && !!numeroTarjeta.codigo.length) {
                  obtenerTrabajador();
                }
              }}
              onBlur={() => {
                refLectora.current.focus();
              }}
              autoFocus={true}
              ref={refLectora}
              placeholder="Número Tarjeta"
            />
            <Button variant="primary" onClick={() => mostrarContrasena()}>
              {numeroTarjeta.mostrarCodigo ? <Visibility /> : <VisibilityOff />}
            </Button>
            <Button variant="primary" onClick={() => obtenerTrabajador()} disabled={!!!numeroTarjeta.codigo.length}>
              <Search />
            </Button>
          </InputGroup>
        </Col>
        <Col xs={8} sm={8} md={8} lg={8}>
          <div className="w-100 d-flex align-items-center justify-content-end">
            <Button
              onClick={() => navigate(`/registro-salidas/${estadoTrabajador.codigoemp}`)}
              variant="secondary"
              disabled={estadoTrabajador.status.toLowerCase() === 'inactivo' || !!!numeroTarjeta.codigo}
            >
              Continuar
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="d-flex align-items-center flex-column" xs={12} sm={12} md={12} lg={12}>
          <div className="w-100">
            <p className="fw-bold">Datos del Trabajador</p>
            {estadoTrabajador.caducidad === 'EXPIRADA' ? (
              <Alert variant="warning" className="mb-4">
                La tarjeta del trabajador está expirada
              </Alert>
            ) : (
              <Card
                className="mb-4"
                style={
                  estadoTrabajador.status.toLowerCase() === 'activo'
                    ? { border: 'solid 3px green', borderRadius: '5px' }
                    : estadoTrabajador.status.toLowerCase() === 'inactivo'
                    ? { border: 'solid 3px red', borderRadius: '5px' }
                    : { border: '1px solid #00000020', borderRadius: '5px' }
                }
              >
                <Card.Header className="p-2">
                  <p className="mx-2 my-1">
                    <span className="fw-bold">Nombre: </span>
                    {detalleTrabajador.nombre}
                  </p>
                  <p className="mx-2 my-1">
                    <span className="fw-bold">Empresa: </span>
                    {detalleTrabajador.empresa}
                  </p>
                  <p className="mx-2 my-1">
                    <span className="fw-bold">Departamento: </span>
                    {detalleTrabajador.departamento}
                  </p>
                  <p className="mx-2 my-1">
                    <span className="fw-bold">Puesto: </span>
                    {detalleTrabajador.puesto}
                  </p>
                  <p className="mx-2 my-1">
                    <span className="fw-bold">Status: </span>
                    {estadoTrabajador.status}
                  </p>
                  <p className="mx-2 my-1">
                    <span className="fw-bold">Tarjeta: </span>
                    {estadoTrabajador.caducidad}
                  </p>
                </Card.Header>
              </Card>
            )}

            <Col x={12} sm={12} md={12} lg={12}>
              {cargandoPrestamos ? (
                <Loading mensaje="Revisando si existen movimientos pendientes..." />
              ) : (
                <Table hover bordered>
                  <thead>
                    <tr>
                      <th>NC</th>
                      <th>Herramienta</th>
                      <th>Nombre Complemento</th>
                      <th>Días Transcurridos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prestamosPendientes?.length ? (
                      <>
                        {prestamosPendientes.map(pres => (
                          <tr
                            key={pres.id}
                            style={
                              tieneEntregaRetrasada(pres.diasTranscurridos, pres.entregado)
                                ? {
                                    backgroundColor: '#c6361b',
                                    color: '#fff',
                                  }
                                : {}
                            }
                          >
                            <td>{pres.nc}</td>
                            <td>{pres.nombre}</td>
                            <td>{pres.complementoNombre}</td>
                            <td>{pres.diasTranscurridos}</td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={12}>No hay prestamos registrados.</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </Col>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
