import fondo from '@app/assets/images/fondo.jpg';
import { useState } from 'react';
import { Provider } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import NavMenu from './components/NavMenu';
import { DEFAULT_DETALLA_ES } from './contants/DEFAULT_DETALLE_ES';
import { AppRoutes } from './contants/app-routes';
import SesionContext from './context/sesionContext';
import { ITrabajadorEntradaSalidaDetalle } from './models/entradas/ITrabajadorEntradaSalida';
import { ROLES } from './models/enums';
import { ISesionContext } from './models/login/ISesionContext';
import AgregarNuevaHerramienta from './pages/AgregarNuevaHerramienta';
import CambiarContrasena from './pages/CambiarContrasena';
import CatalogoAlmacen from './pages/CatalogoAlmacen/CatalogoAlmacen';
import NuevaHerramientaGenerica from './pages/CatalogoAlmacen/NuevaHerramientaGenerica';
import Catalogos from './pages/Catalogos';
import Entrada from './pages/Entradas/Entrada';
import RegistroEntrada from './pages/Entradas/RegistroEntrada';
import Login from './pages/Login';
import Notificaciones from './pages/Notificaciones';
import Preferencias from './pages/Preferencias/Preferencias';
import DetallePrestamo from './pages/Prestamos/DetallePrestamo';
import PrestamoHistorico from './pages/Prestamos/PrestamoHistorico';
import PrestamoIncompleto from './pages/Prestamos/PrestamoIncompleto';
import PrestamosRecientes from './pages/Prestamos/PrestamoReciente';
import Prestamos from './pages/Prestamos/Prestamos';
import RegistroSalida from './pages/Salidas/RegistroSalida';
import Salida from './pages/Salidas/Salida';
import RegistroUsuarios from './pages/Usuarios/RegistroUsuario';
import Usuarios from './pages/Usuarios/Usuarios';
import ActualizarHerramienta from './pages/herramienta/ActualizarHerramienta';
import Certificacion from './pages/herramienta/Certificacion';
import HistorialHerramienta from './pages/herramienta/HistoriaHerramienta';
import Mantenimiento from './pages/herramienta/Mantenimiento';
import TablaHerramienta from './pages/herramienta/TablaHerramienta';
import store from './redux/store';

function App() {
  const [usuario, setUsuario] = useState<string | undefined>(JSON.parse(localStorage.getItem('credenciales'))?.usuario);
  const [datosTrabajadorES, setDatosTrabajadorEs] = useState<ITrabajadorEntradaSalidaDetalle>(DEFAULT_DETALLA_ES);
  const contextData: ISesionContext = {
    usuario,
    setUsuario,
    datosTrabajadorES,
    setDatosTrabajadorEs,
  };
  const roleUsuario = JSON.parse(localStorage.getItem('credenciales'))?.roles[0]?.toLowerCase();

  const navegacion = (elemento: any) => {
    if (!!usuario) {
      const nombreComponente = elemento?.type?.name?.toLowerCase();
      if (
        (roleUsuario === ROLES.USUARIOEXTERNO && nombreComponente === 'notificaciones') ||
        nombreComponente === 'tablaherramienta' ||
        nombreComponente === 'historialherramienta' ||
        nombreComponente === 'prestamos' ||
        nombreComponente === 'prestamosrecientes' ||
        nombreComponente === 'prestamohistorico' ||
        nombreComponente === 'prestamoincompleto' ||
        nombreComponente === 'entradas' ||
        nombreComponente === 'salidas' ||
        nombreComponente === 'registroentrada' ||
        nombreComponente === 'registrosalida'
      ) {
        return elemento;
      }
      return elemento;
    } else {
      return <Navigate to={AppRoutes.Root} />;
    }
  };

  const fondoLogin = (user: string) => {
    if (!!!user) {
      return {
        minHeight: '100vh',
        backgroundImage: `url('${fondo}')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };
    }
    return { minHeight: '100vh' };
  };

  return (
    <Provider store={store}>
      <SesionContext.Provider value={contextData}>
        <main className="d-flex flex-column justify-content-between" style={fondoLogin(usuario)}>
          {!!usuario && <NavMenu />}
          <div className="flex-grow-1">
            <Routes>
              <Route
                path={AppRoutes.Root}
                element={!!usuario ? <Navigate to={AppRoutes.Notificaciones} /> : <Login />}
              />
              <Route path={AppRoutes.Notificaciones} element={navegacion(<Notificaciones />)} />
              <Route path={AppRoutes.CatalogoAlmacen} element={navegacion(<CatalogoAlmacen />)} />
              <Route path={AppRoutes.NuevaHerramientaGenerica} element={navegacion(<NuevaHerramientaGenerica />)} />
              <Route path={AppRoutes.EditarHerramientaGenerica} element={navegacion(<NuevaHerramientaGenerica />)} />
              <Route path={AppRoutes.AgregarHerramienta} element={navegacion(<AgregarNuevaHerramienta />)} />
              <Route path={AppRoutes.Herramienta} element={navegacion(<TablaHerramienta />)} />
              <Route path={AppRoutes.HistorialHerramienta} element={navegacion(<HistorialHerramienta />)} />
              <Route path={`${AppRoutes.Herramienta}/actualizar/:id`} element={navegacion(<ActualizarHerramienta />)} />
              <Route path={`${AppRoutes.Herramienta}/mantenimiento`} element={navegacion(<Mantenimiento />)} />
              <Route path={`${AppRoutes.Herramienta}/certificacion`} element={navegacion(<Certificacion />)} />
              <Route path={`${AppRoutes.Prestamos}`} element={navegacion(<Prestamos />)}>
                <Route path="recientes" element={navegacion(<PrestamosRecientes />)} />
                <Route path="historico" element={navegacion(<PrestamoHistorico />)} />
                <Route path="incompletos" element={navegacion(<PrestamoIncompleto />)} />
              </Route>
              <Route path={`${AppRoutes.DetallePrestamoReciente}`} element={navegacion(<DetallePrestamo />)} />
              <Route path={`${AppRoutes.DetallePrestamoHistorico}`} element={navegacion(<DetallePrestamo />)} />
              <Route path={`${AppRoutes.DetallePrestamoIncompleto}`} element={navegacion(<DetallePrestamo />)} />
              <Route path={AppRoutes.Entradas} element={navegacion(<Entrada />)} />
              <Route path={AppRoutes.RegistroEntrada} element={navegacion(<RegistroEntrada />)} />
              <Route path={AppRoutes.Salidas} element={navegacion(<Salida />)} />
              <Route path={AppRoutes.RegistroSalidas} element={navegacion(<RegistroSalida />)} />
              <Route path={AppRoutes.Catalogos} element={navegacion(<Catalogos />)} />
              <Route path={AppRoutes.Preferencias} element={navegacion(<Preferencias />)} />
              <Route path={AppRoutes.Usuarios} element={navegacion(<Usuarios />)} />
              <Route path={`${AppRoutes.RegistrarUsuario}/:tipo`} element={navegacion(<RegistroUsuarios />)} />
              <Route path={`${AppRoutes.EditarUsuario}/:tipo/:id`} element={navegacion(<RegistroUsuarios />)} />
              <Route path={AppRoutes.CambiarContrasena} element={navegacion(<CambiarContrasena />)} />
            </Routes>
          </div>
          <Footer />
        </main>
      </SesionContext.Provider>
    </Provider>
  );
}

export default App;
