import IndicadoresMantenimiento from '@app/components/IndicadoresMantenimiento';
import Loading from '@app/components/Loading';
import Title from '@app/components/Title';
import { AppRoutes } from '@app/contants/app-routes';
import useError from '@app/hooks/useError';
import { ICatalogoGenerico } from '@app/models/catalogos/ICatalogoGenerico';
import { Catalogo, IndicadoresColoresMantenimiento } from '@app/models/enums';
import { IHerramientaAlmacen } from '@app/models/herramienta/IHerramientaGeneral';
import { IDetalleTrabajadorES } from '@app/models/IDetalleTrabajadorES';
import { getCatalogo, getHerramientaByNcService } from '@app/services';
import { postAddEntrada } from '@app/services/movimientos.service';
import { estatusTrabajadorService, trabajadorTarjetaService } from '@app/services/trabajador.service';
import { qrValidaciones } from '@app/utils/validaciones/qr.validaciones';
import Delete from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

/* eslint-disable react-hooks/exhaustive-deps */
export default function RegistroEntrada() {
  const [guardando, setGuardando] = useState<boolean>(false);
  const [listaObservaciones, setListaObservaciones] = useState<ICatalogoGenerico[]>([]);
  const [listaEstados, setListaEstados] = useState<ICatalogoGenerico[]>([]);
  const [herramientas, setHerramientas] = useState<IHerramientaAlmacen[]>([]);
  const [datosTrabajador, setDatosTrabajador] = useState<IDetalleTrabajadorES>({
    codigocol: 0,
    correo: '',
    departamento: '',
    empresa: '',
    gerencia: '',
    nivelFirma: '',
    nombre: '',
    pueId: 0,
    puesto: '',
    tipo: '',
    unoId: 0,
    usuarioId: '',
    tarjeta: 0,
  });
  const refNumeroControl = useRef<HTMLInputElement | null>(null);
  const formik = useFormik({
    initialValues: {
      numeroControlHerramienta: '',
    },
    validationSchema: qrValidaciones,
    onSubmit: (values: { numeroControlHerramienta: string }) => buscarHerramienta(values.numeroControlHerramienta),
  });
  const { setError, ErrorAlert } = useError();
  const navigate = useNavigate();
  const esEntrada = true;
  const cardNumber = localStorage.getItem('cardNumber');

  const buscarHerramienta = async (numeroControl: string) => {
    try {
      setError('');
      const data = await getHerramientaByNcService(numeroControl);
      if (typeof data === 'string') {
        throw new Error('La herramienta no se encontró.');
      } else {
        //i Si es salida y tiene responsable
        if (!esEntrada && !!data.responsable) {
          throw new Error('La herramienta ya se encuentra prestada.');
        }
        //i Si es salida y tiene resguardo
        else if (!esEntrada && !!data.resguardo) {
          throw new Error('La herramienta tiene resguardo.');
        } else if (!data.responsable) {
          throw new Error('La herramienta se encuentra en almacén.');
        } else {
          recuperarHerramienta({
            id: data.id,
            numeroControl: data.numeroControl,
            fechaCompra: data.fechaCompra,
            modelo: data.modelo,
            numeroSerie: data.numeroSerie,
            marca: data.marca,
            presion: data.presion,
            torque: data.torque,
            velocidadMotor: data.velocidadMotor,
            rangoCapacidadMaxima: data.rangoCapacidadMaxima,
            rangoCapacidadMinima: data.rangoCapacidadMinima,
            herramientaGenerica: data.herramientaGenerica,
            peso: data.peso,
            tipoHerramienta: data.tipoHerramienta,
            tipoHerramientaId: data.tipoHerramientaId,
            complementoNombre: data.complementoNombre,
            ubicacionId: data.ubicacionId,
            estadoId: data.estadoId,
            unidadMedidaId: data.unidadMedidaId,
            costo: data.costo,
            idObservacion: listaObservaciones[0]?.id || 1,
            resguardo: data.resguardo,
            porcentajeMantPre: data.porcentajeMantPre,
          });
        }
      }
    } catch (ex) {
      setError(ex);
    } finally {
      // Cuando se encuentre una herramienta se selecciona para que borre el código anterior
      refNumeroControl?.current?.select();
    }
  };

  const guardarHerramientas = async () => {
    setGuardando(true);
    try {
      await postAddEntrada({
        numeroTarjeta: datosTrabajador.codigocol || datosTrabajador.tarjeta,
        nombreEmpleado: datosTrabajador.nombre,
        nombreEmpleadoEntrada: datosTrabajador.nombre,
        herramientas: herramientas.map((her: IHerramientaAlmacen) => ({
          idHerramienta: her.id,
          resguardo: false,
          idObservacion: +her.idObservacion,
          idEstado: her?.estadoId || 1,
        })),
        tipoMovimiento: 2,
      });
      navigate(AppRoutes.PrestamosRecientes);
    } catch (ex) {
      setError(ex?.response?.data);
    } finally {
      setGuardando(false);
    }
  };

  const recuperarHerramienta = (herramientaRecuperada: IHerramientaAlmacen | string) => {
    if (typeof herramientaRecuperada !== 'string') {
      try {
        if (herramientas.find(her => her.id === herramientaRecuperada.id)) {
          throw new Error('La herramienta ya está en la tabla.');
        } else {
          setHerramientas(hers => [...hers, herramientaRecuperada]);
        }
      } catch (ex) {
        setError(ex);
      }
    }
  };

  const eliminarHerramienta = (idHerramienta: number) => {
    const herramientasActulizadas: IHerramientaAlmacen[] = herramientas.filter(
      (her: IHerramientaAlmacen) => her.id !== idHerramienta
    );
    setHerramientas([...herramientasActulizadas]);
  };

  const inidicarNivelMantenimiento = (estadoMantenimiento: number, tipoHerramientaId: number) => {
    if (!!estadoMantenimiento && tipoHerramientaId !== 6) {
      if (estadoMantenimiento > 0 && estadoMantenimiento < 34) {
        return { backgroundColor: IndicadoresColoresMantenimiento.Estable, color: '#FFFFFF' };
      } else if (estadoMantenimiento > 33 && estadoMantenimiento < 67) {
        return { backgroundColor: IndicadoresColoresMantenimiento.Proximo, color: '#FFFFFF' };
      } else if (estadoMantenimiento > 66) {
        return { backgroundColor: IndicadoresColoresMantenimiento.Urgente, color: '#FFFFFF' };
      }
    }
    return { backgroundColor: '', color: '' };
  };

  const getTrabajador = async () => {
    try {
      const response = await estatusTrabajadorService(+cardNumber);
      if (response.data.caducidad === 'EXPIRADA') {
        return;
      }
      const { data } = await trabajadorTarjetaService(+response.data.codigoemp);
      const detalle =
        data?.codigocol === undefined || data?.codigocol === null
          ? { ...data, nombre: response?.data?.name, puesto: 'Sindicato' }
          : data;
      setDatosTrabajador({ ...detalle, tarjeta: response.data.objectId });
    } catch (ex) {
      setError(ex);
    }
  };

  const getObservaciones = async () => {
    try {
      const response = await getCatalogo(Catalogo.Observaciones);
      setListaObservaciones(response);
    } catch (ex) {
      setError(ex);
    }
  };

  const getEstados = async () => {
    try {
      const response = await getCatalogo(Catalogo.Estados);
      setListaEstados(response);
    } catch (ex) {
      setError(ex);
    }
  };
  const handleEstado = (idHerramienta, idEstado) => {
    setHerramientas(hers => hers.map(her => (her.id === idHerramienta ? { ...her, estadoId: idEstado } : her)));
  };

  const handleObservacion = (idHerramienta, idObservacion) => {
    setHerramientas(hers => hers.map(her => (her.id === idHerramienta ? { ...her, idObservacion } : her)));
  };

  useEffect(() => {
    getEstados();
    getObservaciones();
    getTrabajador();
    refNumeroControl?.current?.focus();
    return () => {
      setGuardando(false);
      setHerramientas([]);
      localStorage.removeItem('cardNumber');
    };
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      <Title titulo="Entrada de Almacén" ruta={AppRoutes.Entradas} />
      {guardando && <Loading mensaje="Guardando herramientas" />}
      <ErrorAlert />
      <Row className="py-2">
        <Col xs={12} sm={12} md={12} lg={12}>
          <IndicadoresMantenimiento />
        </Col>
      </Row>
      <Row className="px-3">
        <Col className="mt-4 d-flex align-items-center justify-content-between" xs={12} sm={12} md={12}>
          <h6 className="fw-bold">Trabajador: {datosTrabajador?.nombre}</h6>
          <h6 className="fw-bold">Empresa: {datosTrabajador?.empresa}</h6>
          <h6 className="fw-bold">Puesto: {datosTrabajador?.puesto}</h6>
          <h6 className="fw-bold">Fecha: {format(new Date(), 'dd/MM/yyyy')}</h6>
        </Col>
      </Row>
      <form onSubmit={formik.handleSubmit}>
        <Row className="flex align-items-end mt-3">
          <Col xs={3} sm={3} md={3} lg={3}>
            <Form.Group>
              <Form.Label>Número Control</Form.Label>
              <Form.Control
                isInvalid={!!formik.errors.numeroControlHerramienta}
                name="numeroControlHerramienta"
                value={formik.values.numeroControlHerramienta}
                onChange={formik.handleChange}
                placeholder="Número Control"
                onBlur={() => {
                  refNumeroControl?.current.focus();
                }}
                ref={refNumeroControl}
              />
              <Form.Control.Feedback className="d-block" type="invalid">
                {formik.errors.numeroControlHerramienta}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} className="d-flex align-items-end mb-1">
            <Button className="mx-3" variant="secondary" type="submit">
              Buscar Herramienta
            </Button>
            <Button
              className="d-block"
              variant="secondary"
              onClick={() => guardarHerramientas()}
              disabled={!!!herramientas.length}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </form>
      <Row className="mt-4">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Table hover bordered>
            <thead>
              <tr>
                <th>Código</th>
                <th>Modelo</th>
                <th>Heramienta</th>
                <th>Complemento Nombre</th>
                <th>Marca</th>
                <th>Observaciones</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {herramientas.length ? (
                <>
                  {herramientas.map((her: IHerramientaAlmacen) => (
                    <tr key={her.id} style={inidicarNivelMantenimiento(her.porcentajeMantPre, her.tipoHerramientaId)}>
                      <td>{her.id}</td>
                      <td>{her.modelo}</td>
                      <td>{her.herramientaGenerica}</td>
                      <td>{her.complementoNombre}</td>
                      <td>{her.marca}</td>
                      <td>
                        <Form.Group className="w-100">
                          <Form.Control
                            as={Form.Select}
                            name="idObservacion"
                            onChange={({ target }) => handleObservacion(her.id, +target.value)}
                            value={her.idObservacion || 1}
                          >
                            {listaObservaciones.map(obs => (
                              <option key={obs.id} value={obs.id}>
                                {obs.descripcion}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className="w-100">
                          <Form.Control
                            as={Form.Select}
                            name="idEstado"
                            onChange={({ target }) => handleEstado(her.id, +target.value)}
                            value={her.estadoId || 1}
                          >
                            {listaEstados.map(est => (
                              <option key={est.id} value={est.id}>
                                {est.descripcion}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <IconButton>
                          <Delete style={{ cursor: 'pointer' }} onClick={() => eliminarHerramienta(her.id)} />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <td colSpan={7}>No hay herramientas registradas.</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
