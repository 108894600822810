export const enum AppRoutes {
  Root = '/',
  Notificaciones = '/notificaciones',
  CatalogoAlmacen = '/catalogo-almacen',
  NuevaHerramientaGenerica = '/nueva-herramienta-generica',
  EditarHerramientaGenerica = '/editar-herramienta-generica/:id',
  AgregarHerramienta = '/agregar-herramienta/:idHerramienta',
  Herramienta = '/herramienta',
  HistorialHerramienta = '/historial/herramienta',
  ActualizarHerramienta = '/herramienta/actualizar/:id',
  HerramientaMantenimiento = '/herramienta/mantenimiento',
  HerramientaCertificacion = '/herramienta/certificacion',
  Prestamos = '/prestamos',
  PrestamosRecientes = '/prestamos/recientes',
  DetallePrestamoReciente = '/prestamos/recientes/:idPrestamo',
  PrestamosHistorico = '/prestamos/historico',
  DetallePrestamoHistorico = '/prestamos/historico/:idPrestamo',
  PrestamosIncompletos = '/prestamos/incompletos',
  DetallePrestamoIncompleto = '/prestamos/incompletos/:idPrestamo',
  Entradas = '/entradas',
  RegistroEntrada = '/registro-entrada/:idTrabajador',
  Salidas = '/salidas',
  RegistroSalidas = 'registro-salidas/:idTrabajador',
  Catalogos = '/catalogos',
  Preferencias = '/preferencias',
  Usuarios = '/usuarios',
  RegistrarUsuario = '/registrar-usuario',
  EditarUsuario = '/editar-usuario',
  CambiarContrasena = '/cambiar-contrasena',
}
