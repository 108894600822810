import Loading from '@app/components/Loading';
import Title from '@app/components/Title';
import useError from '@app/hooks/useError';
import { ICargando } from '@app/models/ICargando';
import { IResetPasswordRequest } from '@app/models/IResetPasswordRequest';
import { resetPasswordUsuarioExterno } from '@app/services/usuarios.service';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';


const CambiarContrasena: React.FC = () => {
    const { ErrorAlert, setError } = useError();
    const [cargando, setCargando] = useState<ICargando>({
        cargando: false,
        mensaje: '',
    });

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>(''); 

    const initialValues: IResetPasswordRequest = {
        newPassword: '',
        confirmPassword: '',
    };

    const validate = (values: IResetPasswordRequest) => {
        const errors: Partial<IResetPasswordRequest> = {};
        if (!values.newPassword) {
            errors.newPassword = 'La nueva contraseña es requerida.';
        }
        if (!values.confirmPassword) {
            errors.confirmPassword = 'La confirmación de la contraseña es requerida.';
        } else if (values.newPassword !== values.confirmPassword) {
            errors.confirmPassword = 'Las contraseñas no coinciden.';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues,
        validate,
        onSubmit: values => generarPassword(values),
    });

    const generarPassword = async (dataRequest: IResetPasswordRequest) => {
        try {
            setCargando({
                cargando: true,
                mensaje: 'Guardando',
            });

            await resetPasswordUsuarioExterno(dataRequest);
            setSuccessMessage('Contraseña cambiada exitosamente.'); 
        } catch (ex) {
            setError(ex);
        } finally {
            setCargando({
                cargando: false,
                mensaje: '',
            });
        }
    };

    useEffect(() => {
        return () => {
            setCargando({
                cargando: false,
                mensaje: '',
            });
            setSuccessMessage('');
        };
    }, []);

    return (
        <Container className="registro-usuario d-flex flex-column mt-5">
            <ErrorAlert />
            {cargando.cargando && <Loading mensaje={cargando.mensaje} />}
            <Title titulo='Cambiar Contraseña' ruta={''} />
            {successMessage && <div className="alert alert-success">{successMessage}</div>} 
            <form className="d-flex justify-content-center" onSubmit={formik.handleSubmit}>
                <div className="w-100" style={{ maxWidth: "600px" }}>
                    <Form.Group className="mb-3">
                        <Form.Label>Nueva Contraseña</Form.Label>
                        <div className="input-group">
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                name="newPassword"
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.newPassword}
                                placeholder="Nueva Contraseña"
                            />
                            <Button
                                variant="outline-secondary"
                                onClick={() => setShowPassword(!showPassword)}
                                type="button"
                                aria-label={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                            >
                                {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                            </Button>
                        </div>
                        <Form.Control.Feedback className="d-block" type="invalid">
                            {formik.errors.newPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Confirmar Contraseña</Form.Label>
                        <div className="input-group">
                            <Form.Control
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                value={formik.values.confirmPassword}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.confirmPassword}
                                placeholder="Confirmar Contraseña"
                            />
                            <Button
                                variant="outline-secondary"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                type="button"
                                aria-label={showConfirmPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                            >
                                {showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                            </Button>
                        </div>
                        <Form.Control.Feedback className="d-block" type="invalid">
                            {formik.errors.confirmPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button className="text-white w-100" type="submit" variant="secondary">
                        Guardar
                    </Button>
                </div>
            </form>
        </Container>
    );
};

export default CambiarContrasena;
