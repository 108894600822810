import Loading from '@app/components/Loading';
import EditarResguardoModal from '@app/components/modals/EditarResguardoModal';
import Title from '@app/components/Title';
import { AppRoutes } from '@app/contants/app-routes';
import useError from '@app/hooks/useError';
import { IAsyncData } from '@app/models';
import { IPrestamo, IResguardo } from '@app/models/prestamos/IPrestamo';
import { getSalidasRecientes } from '@app/services/movimientos.service';
import { getReporteHerramientasPrestamosService } from '@app/services/reportes.service';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { descargarArchivo } from '../../utils/descargarArchivo';
import { ROLES } from '@app/models/enums';
import classNames from 'classnames';
import '../../styles/prestamos.scss';

export default function Prestamo() {
  const [movimientos, setMovimientos] = useState<IAsyncData<IPrestamo[]>>({ status: 'pending', data: [] });
  const [cargando, setCargando] = useState<boolean>(false);
  const [descargandoReporte, setDescargandoReporte] = useState<boolean>(false);
  const [modalResguardo, setModalResguardo] = useState<boolean>(false);
  const [herramientasMovimiento, setHerramientasMovimiento] = useState<IPrestamo[]>([]);
  const [busqueda, setBusqueda] = useState<string>('');
  const [prestamosAux, setPrestamosAux] = useState<IPrestamo[]>([]);
  const [herramientaSeleccionada, setHerramientaSeleccionada] = useState<{
    idHerramienta: number;
    idMovimiento: number;
  }>({
    idHerramienta: 0,
    idMovimiento: 0,
  });
  const [roleActivo, setRoleActivo] = useState<string>('');
  const { setError, ErrorAlert } = useError();

  const obtenerPrestamos = async () => {
    try {
      setCargando(true);
      const prestamos = await getSalidasRecientes();
      prestamos.forEach(x => {
        x.fecha = new Date(x.fecha);
      });
      setMovimientos({ status: 'fulfilled', data: prestamos });
      setPrestamosAux(prestamos); // Guardar datos originales para filtrado
    } catch (ex) {
      setError(ex?.response?.data);
      setMovimientos({ status: 'rejected', data: [] });
    } finally {
      setCargando(false);
    }
  };

  const filtrarPrestamo = () => {
    const resultadoPrestamos = !busqueda
      ? prestamosAux // Usar datos originales si no hay búsqueda
      : prestamosAux.filter((pres: IPrestamo) =>
          (pres.numeroTarjeta && pres.numeroTarjeta.toString().includes(busqueda.toLowerCase())) ||
          (pres.nombreEmpleado && pres.nombreEmpleado.toLowerCase().includes(busqueda.toLowerCase())) ||
          (pres.nombreEmpleadoEntrada && pres.nombreEmpleadoEntrada.toLowerCase().includes(busqueda.toLowerCase())) ||
          (pres.nombre && pres.nombre.toLowerCase().includes(busqueda.toLowerCase())) ||
          (pres.nc && pres.nc.toLowerCase().includes(busqueda.toLowerCase())) ||
          (pres.complementoNombre && pres.complementoNombre.toLowerCase().includes(busqueda.toLowerCase()))
        );

    setMovimientos({ status: 'fulfilled', data: resultadoPrestamos });
  };

  const recuperarResguardoEditado = (nuevoResguardo: IResguardo[]) => {
    const herramientasResguardoEditadas: IPrestamo[] = movimientos.data.map((mv: IPrestamo) => {
      const encontrado = nuevoResguardo.find(hr => hr.idHerramienta === mv.idHerramienta);
      return encontrado ? { ...mv, resguardo: encontrado.resguardo } : mv;
    });
    setModalResguardo(false);
    setMovimientos({ status: 'fulfilled', data: herramientasResguardoEditadas });
  };

  const descargarReporte = async () => {
    try {
      setDescargandoReporte(true);
      const response = await getReporteHerramientasPrestamosService();
      descargarArchivo(response, 'ReportePrestamos.xlsx');
    } catch (ex) {
      setError(ex);
    } finally {
      setDescargandoReporte(false);
    }
  };

  const tieneEntregaRetrasada = (diasTranscurridos: number, estadoHerramienta: boolean): boolean =>
    diasTranscurridos > 5 && !estadoHerramienta;

  useEffect(() => {
    obtenerPrestamos();
    setRoleActivo(JSON.parse(localStorage.getItem('credenciales'))?.roles[0]?.toLowerCase());
    return () => {
      setMovimientos({ status: 'pending', data: [] });
      setCargando(false);
      setDescargandoReporte(false);
      setError('');
    };
  }, []);

  return (
    <Container className="d-flex flex-column mt-5" fluid>
      <ErrorAlert />
      <Title titulo="Préstamos Recientes de Herramienta" ruta="" />
      <Row className="mb-5">
        <Col xs={12} sm={5} md={5} lg={5}>
        <InputGroup>
            <Form.Control
              name="prestamo"
              value={busqueda}
              onChange={({ target }) => setBusqueda(target.value)}
              placeholder="Buscar Préstamo (No.Trabajador/Responsable/NC/Herramienta/ComplementoNombre)"
            />
          <Button variant="primary" onClick={filtrarPrestamo}>
            <SearchIcon />
            </Button>
        </InputGroup>
        </Col>
        <Col xs={12} sm={7} md={7} lg={7} className="d-flex align-items-center justify-content-end">
          <Link to={AppRoutes.Entradas}>
            <Button variant="secondary">Entrega Material</Button>
          </Link>
          &nbsp;&nbsp;
          <Link to={AppRoutes.Salidas}>
            <Button variant="secondary">Salida Material</Button>
          </Link>
          &nbsp;&nbsp;
          {roleActivo !== ROLES.USUARIOEXTERNO && (
            <>
              <Button variant="primary" onClick={() => descargarReporte()} disabled={descargandoReporte}>
                {descargandoReporte ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    &nbsp;&nbsp;Descargando Reporte
                  </>
                ) : (
                  'Descargar Reporte'
                )}
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          {cargando ? (
            <Loading mensaje="Cargando préstamos recientes" />
          ) : (
            <Table hover bordered>
              <thead>
                <tr>
                  <th>No.Trabajador</th>
                  <th>Responsable Salida</th>
                  <th>Responsable Entrada</th>
                  <th>Fecha/Hora Prestamo</th>
                  <th>Días Transcurridos</th>
                  <th>Días Prestada</th>
                  <th>NC</th>
                  <th>Herramienta</th>
                  <th>Complemento Nombre</th>
                  <th>Tipo Herramienta</th>
                  <th>Movimiento</th>
                  <th>Resguardo</th>
                  <th>Auxiliar Taller</th>
                  <th>Observaciones</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {movimientos.data?.length ? (
                  movimientos.data.map((pres: IPrestamo) => {
                    const esRetrasado = tieneEntregaRetrasada(pres.diasTranscurridos, pres.entregado) && !pres.resguardo;
                    return (
                      <tr
                        key={pres.id}
                        className={classNames({
                          'fondo-retrasado': esRetrasado,
                          'fondo-transparente': !esRetrasado,
                        })}
                      >
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {pres.numeroTarjeta}
                        </td>
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {pres.nombreEmpleado}
                        </td>
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {pres.nombreEmpleadoEntrada}
                        </td>
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {format(new Date(pres.fecha), 'dd/MM/yyyy h:mm aa')}
                        </td>
                        <td className={classNames('texto-negro texto-derecha', { 'texto-blanco': esRetrasado })}>
                          {pres.resguardo ? '' : pres.diasTranscurridos || ''}
                        </td>
                        <td className={classNames('texto-negro texto-derecha', { 'texto-blanco': esRetrasado })}>
                          {pres.resguardo ? '' : pres.diasPrestada || ''}
                        </td>
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {pres.nc}
                        </td>
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {pres.nombre}
                        </td>
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {pres.complementoNombre}
                        </td>
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {pres.tipoHerramienta}
                        </td>
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {pres.tipoMovimiento}
                        </td>
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {!pres.entregado ? (pres.resguardo ? 'Si' : 'No') : ''}
                        </td>
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {pres.registroUsuario}
                        </td>
                        <td className={classNames('texto-negro', { 'texto-blanco': esRetrasado })}>
                          {pres?.observaciones ?? 'Sin observaciones'}
                        </td>
                        <td>
                          {pres.tipoMovimiento.toLowerCase() === 'salida' && (
                            <IconButton
                              onClick={() => {
                                setModalResguardo(true);
                                const herramientasDeMovimiento = movimientos.data.filter(
                                  (mov: IPrestamo) => mov.idMovimiento === pres.idMovimiento
                                );
                                setHerramientaSeleccionada({
                                  idHerramienta: pres.id,
                                  idMovimiento: pres.idMovimiento,
                                });
                                setHerramientasMovimiento(herramientasDeMovimiento);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={15}>No hay prestamos registrados.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <EditarResguardoModal
        estadoModal={modalResguardo}
        cambiarEstadoModal={setModalResguardo}
        herramientas={herramientasMovimiento}
        herramientaSeleccionada={herramientaSeleccionada}
        recuperarResguardo={recuperarResguardoEditado}
      />
    </Container>
  );
}
